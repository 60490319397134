
.social_icons {
    display: flex;
    justify-content: center;
    a {
        //flex: 1;
        height: 30px;
        width: 30px;
        margin: 10px 20px 0 20px;
        display: block;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
        &.facebook {
            background-image: url('../img/social/fb.png');
            &--white{
                background-image: url('../img/social/fb_w.png');
            }
        }
        &.youtube {
            background-image: url('../img/social/yt.png');
            &--white{
                background-image: url('../img/social/yt_w.png');
            }
        }
        &.twitter {
            background-image: url('../img/social/twitter.png');
            &--white{
                background-image: url('../img/social/twitter_w.png');
            }
        }
        &.telegram {
            background-image: url('../img/social/telegram.png');
            &--white{
                background-image: url('../img/social/telegram_w.png');
            }
        }
        &.instagram {
            background-image: url('../img/social/instagram.png');
            &--white{
                background-image: url('../img/social/instagram_w.png');
            }
        }
        &.whatsapp {
            background-image: url('../img/social/whatsapp.png');
            &--white{
                background-image: url('../img/social/whatsapp_w.png');
            }
        }
        &.email {
            background-image: url('../img/social/email.png');
            &--white{
                background-image: url('../img/social/email_w.png');
            }
        }
        &.share {
            background-image: url('../img/social/share.png');
            &--white{
                background-image: url('../img/social/share_w.png');
            }
        }
    }
}