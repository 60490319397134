.page--share{

    .athlete-profile{
        &__close{
            text-align: right;
            .label{
                display: inline-block;
                background: #fff5ce;
                transition: background ease .3s;
                padding: 10px;
                width: 100px;
                text-align: center;
                cursor: pointer;
                &:hover{
                    background: darken(#FFF5CE, 30);
                }
            }
            margin-bottom: 10px;
        }
        &__category{
            color: white;
            background: #73aeb9;
            font-size: 20px;
            text-align: center;
            padding: 10px 0;
            margin-bottom: 30px;
        }
        &__sides{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .side{
                &--image{
                    img{
                        width: 100%;
                        height: auto;
                        max-width: 200px;
                    }
                }
                &--main_info{
                    .btn{
                        margin-top: 10px;
                    }
                    table{
                        font-size: 20px;
                        td:first-child{
                            text-align: right;
                            padding-bottom: 10px;
                        }
                        td:last-child{
                            font-weight: bold;
                            padding-left: 20px;
                            padding-bottom: 10px;
                        }
                    }
                }
                &--times{
                    table{
                        border-collapse: collapse;
                        tr{
                            td{
                                border-bottom: 1px solid #5da2ae;
                                padding: 10px;
                            }
                            &.tr-big{
                                font-size: 18px;
                                font-weight: bold;
                            }
                            &:first-child, &:last-child, &:nth-last-child(2){
                                td{
                                    border-bottom: 0;
                                }
                            }
                        }
                    }
                }
                @media screen and (max-width: 865px){
                    width: 49%;
                    &--image{
                        width: 60%;
                        text-align: center;
                        margin: 0 auto 20px auto;
                    }
                }
                @media screen and (max-width: 600px){
                    width: 100%;
                    margin: 10px auto;
                    &--image{
                        width: 60%;
                    }
                }
                @media screen and (max-width: 400px){
                    width: 100%;
                }
            }
        }
    }
}