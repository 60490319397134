$page_width: 1240px;

html{
    background: #fff;
    box-sizing: border-box;
    font-family: LatoLatin;
}
html, body{
    background: #f0eded;
}
*, *:before, *:after {
    box-sizing: inherit;
    font-weight: normal;
}

p{
    margin: 0 0 10px 0;
}

.ljmaratonapp-container{
    font-family: 'LatoLatinWeb', sans-serif;
    // color: #333333;
    .page{
        max-width: 1100px;
        background: #fff;
        margin: 0 auto;
    }

    font-size: 14px;

    @import '../../node_modules/normalize.css';
    @import 'utils/social_icons';
    @import 'PageHome/PageHomeStyle.scss';
    @import 'PageFindPic/PageFindPicStyle.scss';
    @import 'PageLive/PageLiveStyle.scss';
    @import 'PageShare/PageShareStyle.scss';

    .cropper-modal{
        background-color: #fff;
    }

}

.btn{
    display: inline-block;
    background: #fff5ce;
    transition: background ease .3s;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    &:hover{
        background: darken(#FFF5CE, 30);
    }
}

.spinner {
    margin: 0 auto;
    width: 70px;
    text-align: center;
    &> div{
        width: 18px;
        height: 18px;
        background-color: #333;

        border-radius: 100%;
        display: inline-block;
        animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    }
    .bounce1 {
        animation-delay: .466s;
        // animation-delay: -0.32s;
    }
    .bounce2 {
        animation-delay: .932s;
        // animation-delay: -0.16s;
    }
}

.loader,
.loader:after {
    border-radius: 50%;
    width: 20px;
    height: 20px;
}
.loader {
    display: inline-block;
    margin: 1px auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 5px solid rgba(0, 0, 0, .7);
    border-right: 5px solid rgba(0, 0, 0, .7);
    border-bottom: 5px solid rgba(0, 0, 0, .7);
    border-left: 5px solid #ffffff;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}


.notice{
    margin: 20px auto;
    padding: 15px;
    &--green{
        background: lighten(#82ef16, 45%);
        // border: 1px solid #82ef16;
    }
    &--red{
        background: lighten(#ef3016, 45%);
        // border: 1px solid #ef3016;
    }
    &--blue{
        background: #d9edf5;
    }
    &--centered{
        text-align: center;
    }
}

.table{
    border-collapse: collapse;
    transition: opacity ease .5s;
    width: 100%;
    td, th{
        padding: 5px;
        margin: 0;
        text-align: left;
        &.text--center{
            text-align: center;
        }
        &.text--right{
            text-align: right;
        }
    }
    &--stripped tr:nth-child(2n+1) td {
        background: #f3f1e8;
        border-top: 1px solid #c3c3c3;
    }
    &--loading{
        opacity: .8;
    }
    &--responsive {
        @media only screen and (max-width: 600px) {
            thead {
                display: none;
            }
            tbody, tr, td {
                display: block;
            }
            td::before {
                content: attr(data-t);
                display: block;
                // font-weight: bold;
                color: rgba(0, 0, 0, 0.65);
                padding-bottom: 3px;
                padding-top: 10px;
            }
            tr:nth-child(2n+1) td{
                border: 0;
            }
            td {
                background: none;
                border: none;
                padding: 0 10px 10px 10px;
                &.td--responsive-flex{
                    display: flex;
                    &::before{
                        margin-right: 10px;
                    }
                }
                &.td--responsive-flex--full{
                    flex: 1;
                }
            }
            td.hide-r {
                display: none;
            }
            tr {
                border-top: 1px solid #dadada;
                &:first-child {
                    border-top: 0;
                }
            }
            tbody:last-child > tr:last-child > td {
                border-bottom: 0;
            }
        }
    }
    @media screen and (max-width: 768px){
        .hide--m{
            display: none;
        }
    }
    @media screen and (max-width: 640px){
        .hide--s{
            display: none;
        }
    }
}

@keyframes sk-bouncedelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    } 40% {
          -webkit-transform: scale(1.0);
          transform: scale(1.0);
      }
}


@keyframes page-loader-animation {
    100% { background-position: -200%; }
}

@font-face {
    font-family: 'LatoLatinWebHeavy';
    src: url('../fonts/fonts/LatoLatin-Heavy.eot');
    src: url('../fonts/fonts/LatoLatin-Heavy.eot?#iefix') format('embedded-opentype'),
    url('../fonts/fonts/LatoLatin-Heavy.woff2') format('woff2'),
    url('../fonts/fonts/LatoLatin-Heavy.woff') format('woff'),
    url('../fonts/fonts/LatoLatin-Heavy.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}
@font-face {
    font-family: 'LatoLatinWebBold';
    src: url('../fonts/fonts/LatoLatin-Bold.eot');
    src: url('../fonts/fonts/LatoLatin-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/fonts/LatoLatin-Bold.woff2') format('woff2'),
    url('../fonts/fonts/LatoLatin-Bold.woff') format('woff'),
    url('../fonts/fonts/LatoLatin-Bold.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'LatoLatinWebSemibold';
    src: url('../fonts/fonts/LatoLatin-Semibold.eot');
    src: url('../fonts/fonts/LatoLatin-Semibold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/fonts/LatoLatin-Semibold.woff2') format('woff2'),
    url('../fonts/fonts/LatoLatin-Semibold.woff') format('woff'),
    url('../fonts/fonts/LatoLatin-Semibold.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'LatoLatinWeb';
    src: url('../fonts/fonts/LatoLatin-Regular.eot');
    src: url('../fonts/fonts/LatoLatin-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/fonts/LatoLatin-Regular.woff2') format('woff2'),
    url('../fonts/fonts/LatoLatin-Regular.woff') format('woff'),
    url('../fonts/fonts/LatoLatin-Regular.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'LatoLatinThinWeb';
    src: url('../fonts/fonts/LatoLatin-Thin.eot');
    src: url('../fonts/fonts/LatoLatin-Thin.eot?#iefix') format('embedded-opentype'),
    url('../fonts/fonts/LatoLatin-Thin.woff2') format('woff2'),
    url('../fonts/fonts/LatoLatin-Thin.woff') format('woff'),
    url('../fonts/fonts/LatoLatin-Thin.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

.hidden{
    display: none;
}
