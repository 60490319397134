.page--find-pic{
    padding: 10px;
    .stepper{
        display: flex;
        border-bottom: 1px solid #c3c3c3;
        margin-bottom: 20px;
        padding-bottom: 20px;
        &__step{
            flex: 1;
            padding: 10px 0;
            border: 1px solid #c3c3c3;
            text-align: center;
            cursor: pointer;
            border-right: 0;
            &:last-child{
                border-right: 1px solid #c3c3c3;
            }
            &--active{
                background: #a3ccff;
                font-weight: bold;
            }
        }
    }
    .step-content{
        &--1{
            .input-container{
                input{
                    padding: 5px;
                    width: 100%;
                    max-width: 400px;
                }
            }
            .table{
                max-width: 600px;
                td{
                    cursor: pointer;
                }
            }
        }
        &--2{
            .find-header{
                margin-bottom: 10px;
                &__time{
                    display: inline-block;
                    margin-right: 10px;
                    border: 1px solid #c3c3c3;
                    padding: 0 5px;
                    input{
                        border: 0;
                        font-size: 15px;
                        width: 70px;
                        text-align: right;
                        padding: 10px 0;
                    }
                }
                &__time-label{
                    display: inline-block;
                }
                &__loading{
                    margin-left: 20px;
                    font-style: italic;
                }
            }
            .photo-selector{
                display: flex;
                margin-top: 20px;
                .btns{
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    min-height: 108px;
                    &__btn{
                        display: inline-block;
                        background: #fff5ce;
                        transition: background ease .3s;
                        padding: 10px;
                        text-align: center;
                        cursor: pointer;
                        white-space: nowrap;
                        &:hover{
                            background: darken(#FFF5CE, 30);
                        }
                    }
                }
                .pics{
                    padding: 0 20px;
                    flex: 1;
                    display: flex;
                    &__pic{
                        cursor: pointer;
                        img{
                            width: 100%;
                            height: auto;
                        }
                        img{
                            border: 5px solid transparent;
                        }
                        &--active{
                            img{
                                border: 5px solid red;
                            }
                        }
                    }
                }
                @media screen and (max-width: 640px){
                    flex-direction: column;
                    margin-bottom: 20px;
                    .pics{
                        padding: 0;
                        flex-wrap: wrap;
                        &__pic{
                            font-size: 11px;
                            text-align: center;
                            width: 33%;
                        }
                    }
                    .btns{
                        flex-direction: row;
                        min-height: 0;
                        &:first-child{
                            justify-content: flex-start;
                        }
                        &:last-child{
                            justify-content: flex-end;
                        }
                        .btn{
                            flex: 1;
                        }
                    }
                }
            }
            .photo-crop{
                display: flex;
                justify-content: space-around;
                &__original{
                    width: 60%;
                    img#photo-crop-orig{
                        width: 100%;
                        height: auto;
                    }
                }
                &__preview{
                    width: 200px;
                }
                &__preview-element{
                    width: 200px;
                    height: 200px;
                    overflow: hidden;
                }
                &__share-btn{
                    display: block;
                    background: #326dff;
                    transition: background ease .3s;
                    padding: 10px;
                    text-align: center;
                    cursor: pointer;
                    white-space: nowrap;
                    margin-top: 10px;
                    color: #fff;
                    &:hover{
                        background: darken(#326dff, 30);
                    }
                }
                &__preview-label{

                }
                @media screen and (max-width: 640px){
                    flex-direction: column;
                    align-items: center;
                    &__original{
                        width: 100%;
                    }
                    &__preview-label{
                        text-align: center;
                    }
                    &__preview{
                        margin-top: 10px;
                    }
                }
            }
        }
        &--3{
            .share-img-preview{
                text-align: center;
                margin-top: 10px;
                margin-bottom: 20px;
                img{
                    max-width: 600px;
                    width: 100%;
                    height: auto;
                }
            }
            .message{
                font-size: 20px;
                text-align: center;
            }
            .link-location{
                text-align: center;
                input{
                    border: 0;
                    border-bottom: 1px solid #c3c3c3;
                    width: 80%;
                    max-width: 500px;
                    text-align: center;
                    font-size: 20px;
                    padding: 4px 0;
                    margin-top: 20px;
                }
            }
            .links{
                margin-top: 20px;
                text-align: center;
                color: #333;
                &__link{
                    margin: 0 10px;
                    display: inline-block;
                    background: #fff5ce;
                    transition: background ease .3s;
                    padding: 10px;
                    text-align: center;
                    cursor: pointer;
                    white-space: nowrap;
                    text-decoration: none;
                    color: #000;
                    &:hover{
                        background: darken(#FFF5CE, 30);
                    }
                }
            }
        }
    }
}