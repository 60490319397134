.page--live{
    padding: 0;
    .stream-chooser{
        background: #f0eded;
        display: flex;
        .stream{
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            padding-bottom: 10px;
            padding-top: 10px;
            &--selected{
                background: #a3ccff;
            }
            &__icon{
                width: 30px;
                height: 30px;
                background-size: contain;
                &--1{
                    background-image: url('../img/streamico1.png');
                }
                &--2{
                    background-image: url('../img/streamico2.png');
                }
                &--3{
                    background-image: url('../img/streamico3.png');
                }
                &--mapa{
                    background-image: url('../img/streamicomap.png');
                }
                @media screen and (max-width: 400px){
                    display: none;
                }
            }
            &__title{

            }
        }
    }
    .stream-athletes{
        background: #fff;
        padding: 20px;
        .athlete-filter{
            display: flex;
            flex-direction: row;
            margin-bottom: 10px;
            .side{
                .toggle-filters{
                    display: flex;
                    .filter{
                        flex: 1;
                        margin-left: 20px;
                        &:first-child{
                            margin-left: 0;
                        }
                        margin-bottom: 4px;
                        &__label,
                        &__item{
                            display: inline-block;
                            padding: 2px 5px;
                            margin-right: 5px;
                            margin-bottom: 5px;
                        }
                        &__label{
                            background: #344899;
                            color: #fff;
                        }
                        &__item{
                            cursor: pointer;
                            background: #fff5ce;
                            color: #000;
                            transition: background ease .3s;
                            &:hover{
                                background: darken(#fff5ce, 20);
                            }
                            &--active{
                                background: #000;
                                color: #fff;
                                &:hover{
                                    background: #000;
                                }
                            }
                        }
                    }
                    @media screen and (max-width: 600px){
                        flex-direction: column;
                        .filter{
                            margin-left: 0;
                        }
                    }
                }
            }
            .search-box{
                display: flex;
                input{
                    flex: 1;
                    padding: 5px;
                }
                &__clear{
                    background: #344899;
                    color: #fff;
                    cursor: pointer;
                    width: 30px;
                    text-align: center;
                    padding: 5px 0;
                    margin-left: 5px;
                    transition: background ease .3s;
                    &:hover{
                        background: darken(#344899, 20);
                    }
                }
            }
            @media screen and (max-width: 768px){
                flex-direction: column;
            }
        }
        .athlete-list{
            .btn--refresh{
                display: block;
                background: #344899;
                transition: background ease .3s;
                padding: 10px;
                text-align: center;
                cursor: pointer;
                white-space: nowrap;
                color: #fff;
                margin-bottom: 10px;
                &:hover{
                    background: darken(#344899, 30);
                }
            }
            td{
                cursor: pointer;
                transition: background ease .1s;
            }
            tr:hover td{
                background: darken(#f3f1e8, 5);
            }
            .table{
                transition: opacity .1s ease;
                &--loading{
                    opacity: 0;
                }
            }

        }
        .notice-unofficial{
            margin-top: 10px;
            img{
                margin: 5px 0 0 0px;
                height: 30px;
                width: auto;
            }
        }
    }
    .stream-map,
    .stream-video{
        height: 0;
        padding-top: 56.25%;
        // border: 1px solid red;
        position: relative;
        overflow: hidden;
        display: none;
        &--show{
            display: block;
        }
    }
    .stream-map #gmap,
    .stream-video #jwplayer-inst{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }
    .athlete-profile{
        &__close{
            text-align: right;
            .label{
                display: inline-block;
                background: #fff5ce;
                transition: background ease .3s;
                padding: 10px;
                width: 100px;
                text-align: center;
                cursor: pointer;
                &:hover{
                    background: darken(#FFF5CE, 30);
                }
            }
            margin-bottom: 10px;
        }
        &__sides{
            display: flex;
            justify-content: space-between;
            .side{
                width: 49%;
                &--main_info{
                    .btn{
                        margin-top: 10px;
                        color: #000;
                        text-decoration: none;
                    }
                    table{
                        td:first-child{
                            text-align: right;
                        }
                    }
                }
            }
            @media screen and (max-width: 640px){
                flex-direction: column;
                .side{
                    width: 100%;
                    .btn{
                        display: block;
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
    .embed-rec{
        position: relative;
        padding-bottom: 56.25%; /* 16:9 */
        height: 0;
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    .timing-banners{
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media all and (max-width: 600px){
            flex-wrap: wrap;
        }
        div{
            width: 22%;
            @media all and (max-width: 600px){
                width: 48%;
            }
            img{
                width: 100%;
                height: auto;
            }
        }
    }
}